
import React, { useState, useEffect } from "react";
import BlogPost from "./BlogPost";

const Blog = () => {
  const [posts, setPosts] = useState([]);

  useEffect(() => {
    fetch('https://blog.altsmw.com/api/posts')
      .then(res => res.json())
      .then(data => setPosts(data))
      .catch(error => console.error('Error:', error));
  }, []);

  return (
    <div id="blog" className="container" style={{ paddingTop: "150px" }}>
      <div className="blog-text text-center">
        <div className="section-title">
          <h2>Our Blog</h2>
        </div>
      </div>
      <div className="blog-layout">
        <div className="blog-posts">
          {posts.map((post, index) => (
            <BlogPost
              key={index}
              title={post.title}
              content={post.content}
              date={new Date(post.date).toLocaleDateString('en-US', { month: 'short', day: 'numeric', year: 'numeric' })}
              image={post.image}
            />
          ))}
        </div>
        <aside className="blog-sidebar">
          <h3>About Our Blog</h3>
          <p>
            A-Z Language & Translation Solutions provides high-quality
            transcription, translation, and interpretation services with
            unparalleled excellence.
          </p>
          <h3>Categories</h3>
          <ul>
            <li>Translation</li>
            <li>Meetings</li>
            <li>Transcribing</li>
          </ul>
        </aside>
      </div>
    </div>
  );
};

export default Blog;
