// components/Home.jsx
import React from "react";
import { Header } from "./header";
import { Languages } from "./languages";
import { About } from "./about";
import { Services } from "./services";
import { Gallery } from "./gallery";
import { QualityControl } from "./QualityControl";
import { Contact } from "./contact";
import Blog from "./Blog";

const Home = ({ data }) => {
  return (
    <div>
      <Header data={data.Header} />
      <Languages data={data.Languages} />
      <About data={data.About} />
      <Services data={data.Services} />
      
      <Blog data={data.Blog} />
      <Gallery data={data.Gallery} />
      <QualityControl data={data.QualityControl} />
      <Contact data={data.Contact} />
    </div>
  );
};

export default Home;
