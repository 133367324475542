import React, { useState, useEffect } from 'react';

const AdminDashboard = () => {
  const [posts, setPosts] = useState([]);
  const [title, setTitle] = useState('');
  const [content, setContent] = useState('');
  const [image, setImage] = useState(null);
  const [editingId, setEditingId] = useState(null);
  const [feedback, setFeedback] = useState({ message: '', type: '' });

  useEffect(() => {
    fetchPosts();
  }, []);

  const fetchPosts = async () => {
    const response = await fetch('https://blog.altsmw.com/api/posts');
    const data = await response.json();
    setPosts(data);
  };

  const showFeedback = (message, type) => {
    setFeedback({ message, type });
    setTimeout(() => setFeedback({ message: '', type: '' }), 3000);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append('title', title);
    formData.append('content', content);
    if (image) {
      formData.append('image', image);
    }

    const url = editingId 
      ? `https://blog.altsmw.com/api/posts/${editingId}`
      : 'https://blog.altsmw.com/api/posts';

    const method = editingId ? 'PUT' : 'POST';

    try {
      const response = await fetch(url, {
        method,
        body: formData,
      });

      if (response.ok) {
        showFeedback(editingId ? 'Post updated successfully!' : 'Post created successfully!', 'success');
        fetchPosts();
        resetForm();
      }
    } catch (error) {
      showFeedback('Error processing your request', 'error');
    }
  };

  const handleEdit = (post) => {
    setEditingId(post.id);
    setTitle(post.title);
    setContent(post.content);
    showFeedback('Editing post...', 'info');
  };

  const handleDelete = async (id) => {
    if (window.confirm('Are you sure you want to delete this post?')) {
      try {
        const response = await fetch(`https://blog.altsmw.com/api/posts/${id}`, {
          method: 'DELETE',
        });

        if (response.ok) {
          showFeedback('Post deleted successfully!', 'success');
          fetchPosts();
        }
      } catch (error) {
        showFeedback('Error deleting post', 'error');
      }
    }
  };

  const resetForm = () => {
    setTitle('');
    setContent('');
    setImage(null);
    setEditingId(null);
  };

  return (
    <div id="blog" className="container" style={{ paddingTop: "150px" }}>
      <div className="blog-text text-center">
        <div className="section-title">
          <h2>{editingId ? 'Edit Blog Post' : 'Create New Blog Post'}</h2>
        </div>
      </div>
      <div className="blog-layout">
        {feedback.message && (
          <div className={`alert ${feedback.type === 'success' ? 'alert-success' : 'alert-danger'}`} 
               style={{ marginTop: '20px' }}>
            {feedback.message}
          </div>
        )}
        
        <div className="blog-posts">
          <div className="col-md-8 col-md-offset-2">
            <form onSubmit={handleSubmit}>
              <div className="row">
                <div className="col-md-12">
                  <div className="form-group">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Title"
                      value={title}
                      onChange={(e) => setTitle(e.target.value)}
                      required
                    />
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="form-group">
                    <textarea
                      className="form-control"
                      rows="6"
                      placeholder="Content"
                      value={content}
                      onChange={(e) => setContent(e.target.value)}
                      required
                    />
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="form-group">
                    <input
                      type="file"
                      className="form-control"
                      onChange={(e) => setImage(e.target.files[0])}
                      accept="image/*"
                    />
                  </div>
                </div>
              </div>
              <button type="submit" className="btn btn-custom btn-lg">
                {editingId ? 'Update Post' : 'Create Post'}
              </button>
              {editingId && (
                <button 
                  type="button" 
                  className="btn btn-custom btn-lg" 
                  onClick={resetForm}
                  style={{ marginLeft: '10px' }}
                >
                  Cancel Edit
                </button>
              )}
            </form>
          </div>
        </div>

        <div className="section-title" style={{ marginTop: '50px' }}>
          <h2>Manage Posts</h2>
        </div>
        <div className="row">
          {posts.map((post) => (
            <div key={post.id} className="col-md-12" style={{ marginBottom: '20px' }}>
              <div style={{ background: '#f6f6f6', padding: '20px', borderRadius: '8px' }}>
                <h3 style={{ color: '#333' }}>{post.title}</h3>
                <p>{post.content.substring(0, 100)}...</p>
                {post.image && (
                  <img 
                    src={`https://blog.altsmw.com${post.image}`} 
                    alt={post.title}
                    style={{ maxWidth: '200px', marginBottom: '10px' }}
                  />
                )}
                <div>
                  <button 
                    className="btn btn-custom btn-lg"
                    onClick={() => handleEdit(post)}
                    style={{ marginRight: '10px' }}
                  >
                    Edit
                  </button>
                  <button 
                    className="btn btn-custom btn-lg"
                    onClick={() => handleDelete(post.id)}
                    style={{ background: '#ff4444' }}
                  >
                    Delete
                  </button>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default AdminDashboard;
