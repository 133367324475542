// components/Footer.jsx
import React from "react";

const Footer = () => {
  return (
    <footer style={{ padding: "20px", textAlign: "center", background: "#333", color: "#fff" }}>
      <p>
        &copy; 2024 ALTS. Designed by{" "}
        <a 
          href="https://imperialsystemsmw.com/" 
          target="_blank" 
          rel="noopener noreferrer" 
        >
          Imperial Systems in Collaboration with Belea
        </a>
      </p>
    </footer>
  );
};

export default Footer;
