// WhatsAppButton.jsx
import React from 'react';

const WhatsAppButton = () => {
  const phoneNumber = "+265893325204"; // Replace with your phone number in international format
  const message = "Hello! I would like to know more about your services."; // Predefined message
  
  const whatsappLink = `https://wa.me/${phoneNumber}?text=${encodeURIComponent(message)}`;

  return (
    <a href={whatsappLink} target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none' }}>
      <button style={{
        backgroundColor: '#25D366',
        color: 'white',
        border: 'none',
        padding: '15px',
        borderRadius: '50%',
        fontSize: '24px',
        cursor: 'pointer',
        position: 'fixed',
        bottom: '20px',
        right: '20px',
        boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.2)',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}>
        <i className="fab fa-whatsapp"></i>
      </button>
    </a>
  );
};

export default WhatsAppButton;
