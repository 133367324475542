// QualityControl.jsx
import React from "react";

export const QualityControl = (props) => {
  return (
    <div id="quality-control" style={{ paddingTop: "150px", paddingBottom: "50px" }}>
      <div className="container">
        <div className="row">
          <div className="col-xs-12 col-md-12">
            <div className="quality-control-text text-center">
              {/* Apply the same section-title class as About for consistent styling */}
              <div className="section-title">
              <h2>Quality Control</h2>
              <p className="quality-description">
                {props.data ? props.data.paragraph : "loading..."}
              </p>
              </div>
              <h3>How We Ensure Error-Free Translations and Transcripts</h3>
              <div className="list-style text-left">
                <div className="col-lg-12 col-sm-6 col-xs-12">
                  <ul className="quality-list">
                    {props.data
                      ? props.data.Quality.map((d, i) => (
                          <li key={`${d}-${i}`} className="quality-item">
                            <i aria-hidden="true" style={{ color: "#4CAF50", marginRight: "8px" }}></i>
                            {d}
                          </li>
                        ))
                      : "loading"}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
