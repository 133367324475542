import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Navigation from "./components/navigation";
import Footer from "./components/footer";
import Home from "./components/Home";
import { Header } from "./components/header";
import { Languages } from "./components/languages";
import { About } from "./components/about";
import { Services } from "./components/services";
import { Gallery } from "./components/gallery";
import { QualityControl } from "./components/QualityControl";
import { Contact } from "./components/contact";
import Blog from "./components/Blog";
import AdminLogin from "./components/AdminLogin";
import AdminDashboard from "./components/AdminDashboard";
import WhatsAppButton from "./components/WhatsAppButton";
import JsonData from "./data/data.json";
import ProtectedRoute from './components/ProtectedRoute';
import "./App.css";
import { Layout } from "./components/Layout";

const App = () => {
  const [landingPageData, setLandingPageData] = useState({});

  useEffect(() => {
    setLandingPageData(JsonData);
  }, []);

  return (
    <Router>
      <div>
        <Navigation />
        <Routes>
          <Route path="/" element={<Home data={landingPageData} />} />
          <Route path="/header" element={<Header data={landingPageData.Header} />} />
          <Route path="/languages" element={<Languages data={landingPageData.Languages} />} />
          <Route path="/about" element={<About data={landingPageData.About} />} />
          <Route path="/services" element={<Services data={landingPageData.Services} />} />
          <Route path="/gallery" element={<Gallery data={landingPageData.Gallery} />} />
          <Route path="/quality-control" element={<QualityControl data={landingPageData.QualityControl} />} />
          <Route path="/blog" element={<Blog data={landingPageData.Blog} />} />
          <Route path="/contact" element={<Contact data={landingPageData.Contact} />} />
          <Route element={<Layout />}></Route>
          <Route path="/admin" element={<AdminLogin />} />
          <Route path="/admin/dashboard" element={
          <ProtectedRoute>
            <AdminDashboard />
          </ProtectedRoute>
        } />
        </Routes>
        <Footer />
        <WhatsAppButton />
      </div>
    </Router>
  );
};

export default App;
