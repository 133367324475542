import React from 'react';
import Navigation from './navigation';
import Footer from './footer';

export const Layout = ({ children }) => {
  return (
    <>
      <Navigation />
      {children}
      <Footer />
    </>
  );
};
