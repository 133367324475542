import React from "react";

const BlogPost = ({ title, content, date, image }) => {
  const imageUrl = image ? `https://blog.altsmw.com${image}` : null;

  return (
    <div className="blog-post">
      {imageUrl && (
        <img src={imageUrl} alt={title} className="blog-post-image" />
      )}
      <div className="blog-post-content">
        <h3>{title}</h3>
        <p><em>{date}</em></p>
        <p>{content}</p>
      </div>
    </div>
  );
};

export default BlogPost;
